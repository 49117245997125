import React, { useState } from "react";
import { Alert } from "react-bootstrap";

function TwitterApiAlert() {
  const [show, setShow] = useState(true);
  return (
    <Alert
      variant="primary d-flex align-items-center mb-0 border-0 show"
      role="alert"
      dismissible={true}
      show={show}
      onClose={() => setShow(false)}
    >
      <svg
        className="me-2"
        id="info-fill"
        fill="currentColor"
        width="20"
        viewBox="0 0 16 16"
      >
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
      </svg>
      <div>
        Due to recent updates to Twitter's API, our tool features
        are unfortunately not working.
      </div>
    </Alert>
  );
}

export default TwitterApiAlert;
